import { all, call, put, select, takeEvery } from "redux-saga/effects";
import actions from "./actions";
import {
  resetGame,
  createAnotherGame,
  requestAnotherGame,
} from "../../services/gameplay";

function* RESET_GAME() {
  const { active_session, user } = yield select();
  yield call(resetGame, active_session, user.id);
}
function* REQUEST_ANOTHER_GAME() {
  const { active_session, user } = yield select();
  yield call(requestAnotherGame, active_session, user.id);
}
function* CREATE_ANOTHER_GAME() {
  const { active_session, user } = yield select();
  yield call(createAnotherGame, active_session, user.id);
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.RESET_GAME, RESET_GAME),
    takeEvery(actions.CREATE_ANOTHER_GAME, CREATE_ANOTHER_GAME),
    takeEvery(actions.REQUEST_ANOTHER_GAME, REQUEST_ANOTHER_GAME),
  ]);
}
