const DEFAULT_SESSION_DATA = {
  score: 0,
  moves: [],
  available: [],
  sessionId: null,
  waitingOpponent: true,
  created_by: "",
  started_at: null,
  parties: {},
  turn: null,
  owner: null,
  nextGame: null,
  playAgain: [],
};

const DEFAULT_PARTY_DATA = {
  email: "",
  score: 0,
  moves: [],
  available: [],
};

export { DEFAULT_SESSION_DATA, DEFAULT_PARTY_DATA };
