import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { store } from "..";

const firebaseConfig = {
  apiKey: "AIzaSyCxiDUcOV8aGJMD3lGbDuNlINTq4O-WSIc",
  authDomain: "mm-100s.firebaseapp.com",
  projectId: "mm-100s",
  storageBucket: "mm-100s.firebasestorage.app",
  messagingSenderId: "431244647369",
  appId: "1:431244647369:web:5bd165dffad7ae4cf76618",
  measurementId: "G-2GE6WT89Q5",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const database = getDatabase(app);
const firestore = getFirestore(app);
const messaging = getMessaging(app);

function requestPermission() {
  console.log("Requesting permission...");
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification permission granted.");
    }
  });
}

requestPermission();

export { app, analytics, messaging, database, firestore };
