import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import reducers from "./redux/reducers";
import sagas from "./redux/sagas";
import reduxSaga from "redux-saga";
import "./firebase/firebaseApp";
import * as serviceWorker from "./serviceWorker";

const sagaMiddleWare = reduxSaga();

const store = configureStore({
  reducer: reducers(),
  middleware: () => [sagaMiddleWare],
  devTools: process.env.NODE_ENV !== "production",
});

sagaMiddleWare.run(sagas);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

reportWebVitals();

serviceWorker.register();

export { store };
