import {
  addDoc,
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { firestore } from "../firebase/firebaseApp";
import { opponentData } from "./utilites";
import { DEFAULT_PARTY_DATA, DEFAULT_SESSION_DATA } from "../defaults/session";
import { calculateAvailability } from "../gameplay";
import { updateSession } from "./session";

export const makeMove = async (player, sessionId, move) => {
  const sessionRef = doc(firestore, "sessions", sessionId);
  const docSnap = await getDoc(sessionRef);
  if (docSnap.exists()) {
    const session = { id: docSnap.id, ...docSnap.data() };
    const opponent = opponentData(player, session);
    session.turn = opponent;
    const available = calculateAvailability(move, session.moves);
    session.moves = [...session.moves, move];
    session.parties[player].moves.push(move);
    session.available = available;
    await updateDoc(sessionRef, session);
  }
};

export const resetGame = async (session, currentPlayer) => {
  const sessionRef = doc(firestore, "sessions", session.sessionId);
  const opponent = opponentData(currentPlayer, session);

  const data = {
    moves: [],
    available: [],
    parties: {
      [currentPlayer]: DEFAULT_PARTY_DATA,
      [opponent]: DEFAULT_PARTY_DATA,
    },
  };
  await setDoc(sessionRef, data, { merge: true });
};
export const createAnotherGame = async (session, currentPlayer) => {
  console.log("Creating another game...");
  const opponent = opponentData(currentPlayer, session);
  const data = DEFAULT_SESSION_DATA;
  data.started_at = Date.now();
  data.parties = {
    [currentPlayer]: DEFAULT_PARTY_DATA,
    [opponent]: DEFAULT_PARTY_DATA,
  };
  data.turn = session.turn;
  data.waitingOpponent = false;

  try {
    const newSession = await addDoc(collection(firestore, "sessions"), data);
    updateSession(session.sessionId, {
      nextGame: newSession.id,
    });
  } catch (e) {
    console.log(e);
    return false;
  }
};
export const requestAnotherGame = async (session, player) => {
  const sessionRef = doc(firestore, "sessions", session.sessionId);
  const opponent = opponentData(player, session);
  const docSnap = await getDoc(sessionRef);
  if (docSnap.exists()) {
    const existingSession = { id: docSnap.id, ...docSnap.data() };
    if (existingSession.playAgain.includes(opponent)) {
      createAnotherGame(session, player);
    } else {
      existingSession.playAgain = [player];
    }
    await updateDoc(sessionRef, existingSession);
  }
};
